import React from 'react'
import {
  Card,
  Divider,
  Header,
  Icon,
  Image,
  List,
  Segment,
} from 'semantic-ui-react'

import { PieChart } from 'react-minimal-pie-chart'

class TemporaryDashboard extends React.Component {
  state = { logs: [] }

  handleError = (errorObj) => {
    let error = this.containsError(errorObj)
      ? errorObj
      : { message: 'Something Went Wrong' }
    if (error.error) {
      alert(`${error.error}`)
    }
  }

  containsError = (data) => 'error' in data

  handleApiResponse = (data) => {
    if (this.containsError(data)) {
      return Promise.reject(data)
    }
    return data
  }

  getLogs = () => {
    const URL =
      this.props.baseEndpoint() + this.props.secret + `/${this.props.accessId}`
    const options = {
      method: 'GET',
    }
    return fetch(URL, options)
      .then((resp) => resp.json())
      .then(this.handleApiResponse)
      .catch(this.handleError)
  }

  refresh = () => {
    this.getLogs().then((logs) => {
      this.setState({ logs: this.updateLogsWithUniques(logs) })
    })
    setTimeout(this.refresh, 30000)
  }

  updateLogsWithUniques = (logs) => {
    let reversed = logs.reverse()
    let returnArray = reversed.map((log, i) => {
      let index = logs.findIndex((l) => l.pass_serial === log.pass_serial)
      if (index === i) {
        return { ...log, first_tap: true }
      } else {
        return { ...log, first_tap: false }
      }
    })
    return returnArray.reverse()
  }

  componentDidMount() {
    this.refresh()
  }

  render() {
    return (
      <>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: '30px',
          }}
        >
          <Segment>
            <List
              className="example"
              relaxed
              animated
              style={{ overflowY: 'scroll', height: '90vh', width: '80vw' }}
            >
              <Header as="h3">Planet Fans Live Feed</Header>
              <Divider></Divider>

              {this.state.logs.map((log) => (
                <List.Item style={{ marginBottom: '7px' }}>
                  <Image
                    avatar
                    src="https://res-2.cloudinary.com/crunchbase-production/image/upload/c_lpad,f_auto,q_auto:eco/glog8kw0ecdvyoci4ipp"
                  />

                  <List.Content>
                    <List.Header
                      style={
                        log.first_tap ? { color: 'blue' } : { color: 'red' }
                      }
                    >
                      {log.user_name} ({log.user_email}){' '}
                      {log.first_tap
                        ? 'has tapped in at reader '
                        : 'HAS ALREADY TAPPED IN - '}
                      {log.reader.name}, {log.location}
                    </List.Header>
                    <List.Description>
                      {new Date(log.created_at).toDateString()} -{' '}
                      {new Date(log.created_at).toTimeString()}
                    </List.Description>
                  </List.Content>
                </List.Item>
              ))}
            </List>
          </Segment>
        </div>
      </>
    )
  }
}

export default TemporaryDashboard
