import React from "react";
import logo from "./logo.svg";
import "./App.css";
import {
  Input,
  Button,
  Segment,
  Form,
  Header,
  Divider,
  Image,
  Progress,
  Loader,
  Dimmer
} from "semantic-ui-react";
import { SketchPicker, CompactPicker } from "react-color";
import ColorPicker from "./ColorFile";
import appleAdd from "./apple.svg";
import googleAdd from "./google.svg";
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
  isAndroid,
  isIOS
} from "react-device-detect";

// const baseEndpoint = () => {
//   return process.env.NODE_ENV === "development"
//     ? "http://localhost:3000/"
//     : "https://api.passentry.com/";
// };

//https://api.passentry.com/
// const GooglePay = (props) => {
//   return React.createElement("g:savetoandroidpay", props)
// }

// const baseEndpoint = () => "https://passentry-surrey.herokuapp.com/"

class PassCreator extends React.Component {
  state = {
    organisation: null,
    url: null,
    logs: [],
    email: "",
    name: "",
    backgroundColor: "#efede7",
    labelColor: "#c0a37c",
    percentProcess: 0
  };

  componentDidMount() {
    this.randomizeSerial();
    if (this.props.organisationId) {
      fetch(
        this.props.baseEndpoint() +
          "organisations/" +
          this.props.organisationId,
        {
          method: "GET",
          headers: {
            "Content-type": "application/json"
          }
        }
      )
        .then((resp) => resp.json())
        .then((o) => this.setState({ organisation: o }));
    }
  }

  randomizeSerial = () => {
    let newPass = { ...this.state.pass };
    newPass["serialNumber"] = Math.floor(Math.random() * 1000000).toString();
    this.setState({ pass: newPass });
  };

  updateSecondaryValue2 = (event) => {
    let newPass = { ...this.state.pass };
    newPass["storeCard"]["secondaryFields"][1]["value"] = event.target.value;
    this.setState({ pass: newPass, percentProcess: 33 });
  };

  handleChangeBackground = (color) => {
    let newPass = { ...this.state.pass };
    newPass["backgroundColor"] = color.hex;
    this.setState({ pass: newPass, backgroundColor: color.hex });
  };

  handleChangeLabel = (color) => {
    let newPass = { ...this.state.pass };
    newPass["labelColor"] = color.hex;
    this.setState({ pass: newPass, labelColor: color.hex });
  };

  postApplePass = () => {
    this.randomizeSerial();
    this.setState({ percentProcess: 100 });
    fetch(this.props.baseEndpoint() + "passes", {
      method: "POST",
      responseType: "blob",
      body: JSON.stringify({
        name: this.state.name,
        email: this.state.email,
        organisationId: this.state.organisation.id
      }),
      headers: {
        "Content-type": "application/json"
      }
    })
      .then((response) => {
        return response.blob();
      })
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "LoyaltyCard.pkpass");
        document.body.appendChild(link);
        link.click();
      });
  };

  postGooglePass = () => {
    this.randomizeSerial();
    this.setState({ percentProcess: 100 });
    fetch(this.props.baseEndpoint() + "create_google_pass", {
      method: "POST",
      body: JSON.stringify({
        name: this.state.name,
        email: this.state.email,
        organisationId: this.state.organisation.id
      }),
      headers: {
        "Content-type": "application/json"
      }
    })
      .then((resp) => resp.json())
      .then((response) => {
        // this.setState({ url: response["url"] });
        window.location.href = response["url"];
      });
  };
  // pass_serial: "1f74130d4a6bb757fbb0",
  stamp_pass = () => {
    const body = { message: this.state.email };

    fetch("https://oldapi.passentry.com/" + "stamp_pass", {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        "Content-type": "application/json"
      }
    });
  };

  render() {
    return (
      <div className="App">
        <Segment
          style={{
            backgroundColor: "#29323A",
            height: "52px",
            padding: "5px"
          }}
        >
          <Image
            size="small"
            src="https://uploads-ssl.webflow.com/5df785e26482d831b637d003/5ea1ed3f10f64936fcd0a99e_Logo%20on%20dark_1.svg"
          />

          {/* <Image
              style={{margin: "0px"}}
            size="mini"
            src={
              "https://res-2.cloudinary.com/crunchbase-production/image/upload/c_lpad,f_auto,q_auto:eco/glog8kw0ecdvyoci4ipp"
            }
          ></Image> */}
          <Progress
            color="blue"
            style={{ backgroundColor: "#FF8477" }}
            percent={this.state.percentProcess}
            attached="bottom"
          />
        </Segment>
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center"
          }}
        >
          <Segment
            style={{
              minWidth: "350px",
              position: "absolute",
              margin: "auto",
              marginTop: "30px"
            }}
          >
            {this.state.organisation ? (
              <Image
                style={{ margin: "auto" }}
                size="medium"
                src={this.state.organisation.logo_url}
              ></Image>
            ) : (
              <Dimmer inverted active>
                <Loader />
              </Dimmer>
            )}
            <Divider hidden style={{ marginBottom: "5px", marginTop: "5px" }} />
            <Form>
              <Header style={{ fontSize: 12 }} floated="left" as="h5">
                Full Name:
              </Header>
              <Form.Input
                placeholder="Enter your full name"
                value={this.state.name}
                onChange={(e) => this.setState({ name: e.target.value })}
              ></Form.Input>
              <Header style={{ fontSize: 12 }} floated="left" as="h5">
                Email:
              </Header>
              <Form.Input
                value={this.state.email}
                onChange={(e) =>
                  this.setState({ percentProcess: 66, email: e.target.value })
                }
                placeholder="Enter your email"
              ></Form.Input>
              {!isAndroid && (
                <Image
                  size="small"
                  src={appleAdd}
                  style={{
                    margin: "auto",
                    marginTop: "30px",
                    marginBottom: "15px",
                    cursor: "pointer"
                  }}
                  onClick={() => this.postApplePass()}
                ></Image>
              )}
              {isAndroid && <Divider></Divider>}
              {isAndroid && (
                <Image
                  src={googleAdd}
                  fluid
                  onClick={() => this.postGooglePass()}
                  style={{
                    margin: "auto",
                    marginTop: "10px",
                    marginBottom: "5px",
                    cursor: "pointer"
                  }}
                ></Image>
              )}
              {/* {this.state.url && (
                <Image
                  as="a"
                  href={this.state.url}
                  fluid
                  src={googleAdd}
                  style={{
                    margin: "auto",
                    marginTop: "10px",
                    marginBottom: "5px",
                    cursor: "pointer",
                  }}
                ></Image>
                <Button primary href={this.state.url} target="_blank">
                  Unique Google Pay Link
                </Button>
              )} */}

              {/* <Button onClick={() => this.stamp_pass()}>Stamp Pass</Button> */}
            </Form>
          </Segment>
        </div>
        <div
          style={{
            position: "fixed",
            bottom: 0,
            width: "100vw",
            padding: "2px"
          }}
          attached="bottom"
        >
          <span style={{ margin: "auto" }}>
            © 2020 PassEntry™ - Made in U.K. - Patent Pending
          </span>
        </div>
      </div>
    );
  }
}

export default PassCreator;
